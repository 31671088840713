const codes = ['bf50', 'aaf50'];

export const addDiscountSuffix = (
  couponCode: string | undefined,
  isPerpetual: boolean
) => {
  const code = couponCode?.toLowerCase().trim();

  if (code && codes.includes(code)) {
    return isPerpetual ? code + '-perp' : code + '-rto';
  }

  return couponCode;
};
