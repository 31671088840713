import React, {FC} from 'react';
import {AxiosError} from 'axios';
import Theme from '../../theme';
import {Card, CardStyle} from '../Card';
import {ErrorContainer} from '../ErrorContainer';
import {Group} from '../Group';
import {GutterMaxWidth} from '../Spacing/Gutters';

interface ErrorMessageProps {
  maxWidth: GutterMaxWidth;
  error?: AxiosError;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({maxWidth, error}) => {
  if (error?.code === 'ECONNABORTED') {
    return null;
  }

  return (
    <ErrorContainer
      maxWidth={maxWidth}
      paddingTop
      data-testid="query-parameter-error"
    >
      <Group flexDirection="column" justifyContent="center">
        <h1
          style={{paddingLeft: `${Theme.space[5]}px`}}
          color={Theme.colours.red}
        >
          An error has occurred
        </h1>
        <Card cardStyle={CardStyle.OUTLINE} colour={Theme.colours.red}>
          We're sorry, it appears an error has occurred. Please retry, if this
          problem persists contact our support team.
        </Card>
      </Group>
    </ErrorContainer>
  );
};
