import React, {FunctionComponent, useEffect, useState} from 'react';
import {graphql, navigate, useStaticQuery} from 'gatsby';
import {useLocation} from '@reach/router';
import {
  Coupon,
  PaymentsApi,
  UserApi,
  User,
} from '@focusrite-novation/ampify-api';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {FooterContent} from '../types/FooterContent';
import getQueryParameter from '../utils/get-query-parameters';
import {
  getUserFromLocalStorage,
  clearUserFromLocalStorage,
  clearTokenFromLocalStorage,
} from '../utils/user-info';
import {
  setColours,
  setDefaultButton,
  useHasMounted,
} from '@focusrite-novation/ampify-web-ui';
import {isPerpetual, parseCountriesFromResponse} from '../lib/payment';
import BackgroundImage, {IFluidObject} from 'gatsby-background-image';
import {Card, CardStyle} from '../components/Card';
import {Elements, RecurlyProvider} from '@recurly/react-recurly';
import * as ReactRecurly from '@recurly/react-recurly';
import {RecurlyError} from 'recurly__recurly-js';
import styled from 'styled-components';
import {Theme} from '../theme/Theme';
import {OAuthOptions} from '../utils/auth';
import {PaymentFormRecurlyContainer} from '../components/PaymentFormRecurlyContainer/PaymentFormRecurlyContainer';
import * as loginUrl from '../utils/login-url';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {
  CardSubscriptionRequestBody,
  SubscriptionRequestBody,
  CardPurchaseRequestBody,
  isSubscriptionRequestBody,
  PurchaseRequestBody,
} from '../components/PaymentFormRecurlyContainer/PaymentFormRecurlyContainer.utils';
import {Dictionary} from '../types/Dictionary';
import {LoadingIcon} from '../components/Icon/LoadingIcon';
import {Button} from '../components/Button';
import {
  registerPurchase,
  registerSubscription,
  PaymentsError,
} from '../lib/register-subscription';
import {ErrorMessage} from '../components/ErrorMessage';
import {PaymentPageQuery} from './__generated__/PaymentPageQuery';
import {createApi} from '../lib/api';
import {ButtonProps} from '@focusrite-novation/ampify-web-ui/dist/types/components/Button';
import {fetchPlans, fetchPurchases} from '../lib/my-account';
import {
  fetchHardwareRegistrations,
  getHardwareCoupon,
} from '../lib/my-account/fetch-hardware-registrations';
import {
  getLimiterCoupon,
  isBundle4Owned,
} from '../lib/my-account/fetch-products';
const {colours} = Theme;

interface PaymentPageProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
}

const PaymentPageContent = styled(Gutters)`
  padding-top: ${Theme.space[6]}px;
  margin: 0 auto;

  form {
    width: 100%;
  }

  h3 {
    text-align: center;
    padding-bottom: ${Theme.space[4]}px;
    color: ${Theme.colours.white};
  }

  #submit-payment {
    width: auto !important;
    margin: 0 auto;
  }
`;

const SubmittingPageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  min-height: 400px;

  & > h3 {
    color: ${Theme.colours.black};
    padding-bottom: ${Theme.space[2]}px;
    padding-top: ${Theme.space[4]}px;
  }

  & > p {
    color: ${Theme.colours.black};
    padding-bottom: ${Theme.space[2]}px;
  }
`;

const ThreeDChallengeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
`;

export interface PaymentOptions {
  user: User;
  redirectUrl: string;
  productName: string;
  planId: string;
  itemId: string;
  oauth: OAuthOptions;
  supportedCountries: string[];
}

const PaymentPage: FunctionComponent<PaymentPageProps> = ({
  pageContext: {contentfulFooterContent, customerPortalBaseUrl},
}) => {
  const data: PaymentPageQuery = useStaticQuery(graphql`
    query PaymentPageQuery {
      payment: file(relativePath: {eq: "paymentform-bg-screenbright.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      site {
        siteMetadata {
          ampifyApi {
            baseUrl
          }
          recurlyPublicKey
          auth {
            client_id
            scope
          }
          softwareProductIds
        }
      }
    }
  `);

  const [paymentOptions, setPaymentOptions] = useState<PaymentOptions | false>(
    false
  );
  const [subscribeRequestBody, setSubscribeRequestBody] =
    useState<CardSubscriptionRequestBody>();
  const [purchaseRequestBody, setPurchaseRequestBody] =
    useState<CardPurchaseRequestBody>();
  const [couponCode, setCouponCode] = useState<string>('');
  const [minBackgroundHeight, setMinBackgroundHeight] = useState(500);
  const [error, setError] = useState<string>('');
  const [invalidQueryParameters, setInvalidQueryParameters] =
    useState<boolean>(false);
  const [threeDToken, setThreeDToken] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptionTerm, setSubscriptionTerm] = useState<number>(15);
  const [formTitle, setFormTitle] = useState<string>(
    'Sign up for monthly payments'
  );
  const location = useLocation();

  const [hasAccess, setHasAccess] = useState<boolean>(false);

  const siteMetadata = data.site?.siteMetadata;
  const ampifyBaseUrl = siteMetadata?.ampifyApi?.baseUrl || '';
  const auth = siteMetadata?.auth;
  if (!auth || !auth.client_id || !auth.scope) {
    throw new Error('No oauth settings configured');
  }
  const authOpts = {
    client_id: auth.client_id,
    scope: auth.scope,
  };

  const paymentsApi = createApi(PaymentsApi, ampifyBaseUrl, authOpts);
  const userApi = createApi(UserApi, ampifyBaseUrl, authOpts);

  const fetchSubscriptionTerm = async (planId: string): Promise<number> =>
    (await paymentsApi.getPlans()).data?.plans?.find(
      (plan) => plan.id === planId
    )?.term || 15;

  useEffect(() => {
    const slug = getQueryParameter(window, 'slug');
    const planId = getQueryParameter(window, 'planId');
    const itemId = getQueryParameter(window, 'itemId');
    const productName = getQueryParameter(window, 'productName');
    const softwareId = getQueryParameter(window, 'softwareId');

    if (isPerpetual(location)) {
      setFormTitle(`Purchase ${productName}`);
    }

    try {
      const queryParameters: Dictionary<string> = {
        slug,
        softwareId,
        productName,
      };

      if (!itemId.length && planId.length) {
        queryParameters.planId = planId;
      }

      if (!planId.length && itemId.length) {
        queryParameters.itemId = itemId;
      }

      if (!planId.length && !itemId.length) {
        setInvalidQueryParameters(true);
        throw new Error('missing planId and itemId');
      }

      Object.keys(queryParameters).forEach((key) => {
        if (queryParameters[key] === '') {
          setInvalidQueryParameters(true);
          throw new Error(`missing query parameter ${key}`);
        }
      });
    } catch (error) {
      console.error(error);
      return;
    }

    const updateSubscriptionTerm = async () => {
      if (!isPerpetual(location) && paymentOptions && paymentOptions?.planId) {
        const term = await fetchSubscriptionTerm(paymentOptions!.planId);
        setSubscriptionTerm(term);
      }
    };

    updateSubscriptionTerm();

    let user: User;

    try {
      const possibleUser = getUserFromLocalStorage();
      if (!possibleUser) {
        throw new Error('User not found');
      }
      if (typeof possibleUser.firstName !== 'string') {
        // log an error and carry on (it seems some users legitimately have null as firstName)
        console.error(
          new Error(`User: ${possibleUser?.id} is missing a first name`)
        );
      }
      user = possibleUser;
    } catch (error) {
      clearUserFromLocalStorage();
      clearTokenFromLocalStorage();
      navigate(
        loginUrl.buildPurchaseUrl({
          customerPortalBaseUrl,
          productName,
          planId,
          itemId,
          softwareId,
          slug,
        })
      );
      return;
    }

    const oauth: OAuthOptions = siteMetadata?.auth as OAuthOptions;

    async function fetchAllowedCountries() {
      try {
        const api = new PaymentsApi({
          basePath: ampifyBaseUrl,
          isJsonMime: () => true,
        });

        const countriesResponse = (await api.getSupportedCountries()).data;
        const supportedCountries = countriesResponse.hasOwnProperty('countries')
          ? // @ts-ignore
            parseCountriesFromResponse(countriesResponse.countries)
          : countriesResponse;

        const redirectUrl = planId
          ? `/products/${slug}/getting-started?planId=${planId}`
          : `/products/${slug}/getting-started?itemId=${itemId}`;

        setPaymentOptions({
          user,
          redirectUrl,
          productName,
          planId,
          itemId,
          oauth,
          supportedCountries,
        });
      } catch (error) {
        // if this endpoint fails we can safely ignore it as the server
        // will reject any incorrect country - this code is mostly for UX
        console.error(error);
        setError('woops');
      }
    }

    setMinBackgroundHeight(
      document && document.body ? document.body.clientWidth * 0.67 : 500
    );

    fetchAllowedCountries();
  }, [(paymentOptions as PaymentOptions)?.itemId]);

  useEffect(() => {
    async function checkAccess() {
      try {
        const [userPurchases, userPlans, userHardware, plans] =
          await Promise.all([
            fetchPurchases(paymentsApi),
            fetchPlans(paymentsApi),
            fetchHardwareRegistrations(
              userApi,
              siteMetadata?.softwareProductIds
            ),
            paymentsApi.getPlans(),
          ]);

        const coupon = getHardwareCoupon(userHardware, location);
        if (coupon) {
          setCouponCode(coupon);
        }

        const isBundle4Owner = isBundle4Owned(
          plans.data.plans!,
          userPlans,
          userPurchases
        );

        const params = new URLSearchParams(location.search);
        const currentPlugin = params.get('slug');

        if (isBundle4Owner && currentPlugin === 'fast-limiter') {
          setCouponCode(getLimiterCoupon(location));
        }

        const accessibleSoftwareIds = [
          ...userPurchases.flatMap(({softwareIds}) => softwareIds),
          ...userPlans.flatMap(({softwareIds}) => softwareIds),
        ];

        const softwareId = getQueryParameter(window, 'softwareId');

        if (accessibleSoftwareIds.includes(softwareId)) {
          setHasAccess(true);
        }
      } catch (error) {
        setError('There was an error. Please reload this page and try again.');
      }

      setIsLoading(false);
    }

    checkAccess();
  }, []);

  const hasMounted = useHasMounted();

  const handlePurchase = async (requestBody: PurchaseRequestBody) => {
    if (paymentOptions) {
      setIsSubmitting(true);
      window.scrollTo(0, 0);

      if (hasAccess) {
        navigate('/my-account');
        return;
      }

      await registerPurchase(requestBody, paymentsApi);
      navigate(paymentOptions.redirectUrl);
    }
  };

  const handleSubscribe = async (requestBody: SubscriptionRequestBody) => {
    if (paymentOptions) {
      setIsSubmitting(true);
      window.scrollTo(0, 0);

      if (hasAccess) {
        navigate('/my-account');
        return;
      }

      const paymentsApiWithForce = createApi(
        PaymentsApi,
        ampifyBaseUrl,
        authOpts,
        {forceRefreshToken: true}
      );

      await registerSubscription(requestBody, paymentsApiWithForce);
      navigate(paymentOptions.redirectUrl);
    }
  };

  const handleThreeDSecureToken = async (token: {id: string}) => {
    try {
      if (subscribeRequestBody) {
        subscribeRequestBody!.threeDSecureActionResultTokenId = token.id;
        await handleSubscribe(subscribeRequestBody!);
      } else if (purchaseRequestBody) {
        purchaseRequestBody!.threeDSecureActionResultTokenId = token.id;
        await handlePurchase(purchaseRequestBody!);
      }
    } catch (error: any) {
      setIsSubmitting(false);
      handleSubscriptionError(error);
    }
  };

  const handleSubscriptionError = (error: RecurlyError | PaymentsError) => {
    setThreeDToken('');
    setError(error.message);
  };

  const handleSubmit = async (
    requestBody: SubscriptionRequestBody | PurchaseRequestBody
  ) => {
    try {
      if (isSubscriptionRequestBody(requestBody)) {
        await handleSubscribe(requestBody);
      } else {
        await handlePurchase(requestBody);
      }
    } catch (error: any) {
      setIsSubmitting(false);
      if (error.errorCode === 'ThreeDSecureActionRequired') {
        if (isSubscriptionRequestBody(requestBody)) {
          setSubscribeRequestBody(requestBody as CardSubscriptionRequestBody);
        } else {
          setPurchaseRequestBody(requestBody as CardPurchaseRequestBody);
        }
        setThreeDToken(error.threeDSecureActionTokenId);
      } else {
        handleSubscriptionError(error);
      }
    }
  };

  const fetchCoupon = async (couponId: string): Promise<Coupon> =>
    (await paymentsApi.getCoupon(couponId)).data;

  setColours({
    selected: colours.blue,
    warning: colours.orange,
    error: colours.red,
  });

  setDefaultButton(Button as React.FunctionComponent<ButtonProps>);

  const ThreeDSecureAction = (ReactRecurly as any).ThreeDSecureAction;

  return (
    <Layout
      {...contentfulFooterContent}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO title="Payment" />
      {invalidQueryParameters ? (
        <ErrorMessage maxWidth={GutterMaxWidth.LARGE} />
      ) : (
        <BackgroundImage
          style={{
            width: '100%',
            boxSizing: `inherit`,
            minHeight: `${minBackgroundHeight}px`,
            height: 'auto',
            position: 'unset',
            backgroundSize: 'contain',
            backgroundPositionY: 'top',
            backgroundColor: '#271314',
          }}
          fluid={data?.payment?.childImageSharp?.fluid as IFluidObject}
        >
          <PaymentPageContent maxWidth={GutterMaxWidth.NORMAL}>
            {paymentOptions && hasMounted ? (
              <>
                <h3 data-testid="payment-form-title">{formTitle}</h3>
                <Card
                  flexDirection="column"
                  cardStyle={CardStyle.SOLID}
                  colour={Theme.colours.white}
                >
                  {isSubmitting ? (
                    <SubmittingPageContent>
                      <LoadingIcon />
                      <h3>One moment, please...</h3>
                      <p>We're setting up your payment</p>
                    </SubmittingPageContent>
                  ) : (
                    !isLoading && (
                      <RecurlyProvider
                        publicKey={
                          data?.site?.siteMetadata?.recurlyPublicKey || ''
                        }
                      >
                        {threeDToken ? (
                          <ThreeDChallengeContainer data-testid="payment-form-threeD">
                            <ReactRecurly.ThreeDSecureAction
                              actionTokenId={threeDToken}
                              onToken={handleThreeDSecureToken}
                              className="recurly-three-d-secure-action"
                              onError={handleSubscriptionError}
                            />
                          </ThreeDChallengeContainer>
                        ) : (
                          <Elements>
                            <PaymentFormRecurlyContainer
                              hasAccess={hasAccess}
                              error={error}
                              user={paymentOptions.user}
                              supportedCountries={
                                paymentOptions.supportedCountries
                              }
                              submitButtonText={
                                isPerpetual(location)
                                  ? 'Buy now'
                                  : 'Start monthly payments'
                              }
                              handleSubmit={handleSubmit}
                              fetchCoupon={fetchCoupon}
                              subscriptionTerm={subscriptionTerm}
                              planId={paymentOptions.planId}
                              itemId={paymentOptions.itemId}
                              initialPaymentFormFieldValues={{
                                couponCode,
                              }}
                            />
                          </Elements>
                        )}
                      </RecurlyProvider>
                    )
                  )}
                </Card>
              </>
            ) : (
              <>
                <h3 data-testid="payment-loading-title">Loading...</h3>
                <Card
                  flexDirection="row"
                  justifyContent="center"
                  cardStyle={CardStyle.SOLID}
                  colour={Theme.colours.white}
                >
                  <LoadingIcon />
                </Card>
              </>
            )}
          </PaymentPageContent>
        </BackgroundImage>
      )}
    </Layout>
  );
};

export default PaymentPage;
