import {
  Currency,
  PaymentFormFields,
  Price,
} from '@focusrite-novation/ampify-web-ui';
import {User} from '@focusrite-novation/ampify-api';
import {UseCheckoutPricingState} from '@recurly/react-recurly';
import {user} from '../../../cypress/fixtures/mock-requests';

export interface RecurlyToken {
  id: string;
  type: string;
}

export interface BaseProductRequest {
  userId: number;
  email?: string;
  firstName: string;
  lastName: string;
}

export interface CardSubscriptionRequestBody extends BaseProductRequest {
  couponCode?: string;
  token: string;
  address: {
    country: string;
  };
  currency: string;
  threeDSecureActionResultTokenId?: string;
  planId: string;
}

export interface CouponSubscriptionRequestBody extends BaseProductRequest {
  couponCode: string;
  planId: string;
}

export interface CouponPurchaseRequestBody extends BaseProductRequest {
  couponCode: string;
  itemId: string;
}

export interface CardPurchaseRequestBody extends BaseProductRequest {
  couponCode?: string;
  token: string;
  address: {
    country: string;
  };
  currency: string;
  threeDSecureActionResultTokenId?: string;
  itemId: string;
}

export const isSubscriptionRequestBody = (
  request: SubscriptionRequestBody | PurchaseRequestBody
): request is SubscriptionRequestBody => {
  return !!(request as any).planId;
};

export const createCouponPurchaseRequestBody = (
  {email, firstName, lastName, couponCode}: PaymentFormFields,
  itemId: string,
  user: User
): CouponPurchaseRequestBody => ({
  userId: user.id as number,
  email,
  firstName,
  lastName,
  couponCode: couponCode ? couponCode.trim() : '',
  itemId,
});

export const createCardSubscriptionRequestBody = (
  values: PaymentFormFields,
  planId: string,
  user: User,
  currency: Currency,
  token: string
): CardSubscriptionRequestBody => {
  let request: CardSubscriptionRequestBody = {
    token,
    email: values.email?.trim(),
    currency,
    userId: user.id as number,
    firstName: values.firstName,
    lastName: values.lastName,
    address: {
      country: values.country,
    },
    planId,
    couponCode: values.couponCode || '',
  };

  return request;
};

export const createCouponSubscriptionRequestBody = (
  {email, firstName, lastName, couponCode}: PaymentFormFields,
  planId: string,
  user: User
): CouponSubscriptionRequestBody => ({
  userId: user.id as number,
  email,
  firstName,
  lastName,
  couponCode: couponCode ? couponCode.trim() : '',
  planId,
});

export const createCardPurchaseRequestBody = (
  values: PaymentFormFields,
  itemId: string,
  user: User,
  currency: Currency,
  token: string
) => {
  let request: CardPurchaseRequestBody = {
    token,
    email: values.email?.trim(),
    currency,
    userId: user.id as number,
    firstName: values.firstName,
    lastName: values.lastName,
    address: {
      country: values.country,
    },
    itemId,
    couponCode: values.couponCode || '',
  };

  return request;
};

export interface PricingOptions {
  // if it's a perpetual purchase we'll be looking only at
  // what is due right now
  perpetual: boolean;
}

export const getPrice = (
  priceState: UseCheckoutPricingState,
  options: PricingOptions = {perpetual: false}
): Price | undefined => {
  const hasPrice = options.perpetual
    ? !priceState.loading && priceState.price.now
    : !priceState.loading && priceState.price.next;

  return hasPrice
    ? {
        currencySymbol: priceState.price.currency.symbol,
        total: `${
          options.perpetual
            ? priceState.price.now.total
            : priceState.price.next.total + '/month'
        }`,
        taxInclusive: priceState.price.taxes.length > 0,
      }
    : undefined;
};

export type SubscriptionRequestBody =
  | CouponSubscriptionRequestBody
  | CardSubscriptionRequestBody;

export type PurchaseRequestBody =
  | CouponPurchaseRequestBody
  | CardPurchaseRequestBody;
