import {
  UserApi,
  GetUserRegistrations,
  GetUserRegistrationsRegistrationsInner,
} from '@focusrite-novation/ampify-api';
import {WindowLocation} from '@reach/router';

import {isPerpetual} from '../payment';

export enum HardwareCoupon {
  RTO = 'portal-discount-rto',
  Perpetual = 'portal-discount-perpetual',
}

export enum BalancerHardwareCoupon {
  RTO = 'dd61382a-60ef-49c5-b355-fed58a4789f0-rto',
  Perpetual = 'dd61382a-60ef-49c5-b355-fed58a4789f0-perpetual',
}

export enum Bundle5Coupon {
  RTO = 'hw-bundle-discount-rto',
  Perpetual = 'hw-bundle-discount-perpetual',
}

interface Types {
  RTO: string;
  Perpetual: string;
}

const couponsMap = new Map<string, Types>([
  ['fast-balancer', BalancerHardwareCoupon],
  ['fast-bundle', Bundle5Coupon],
]);

export const filterSoftware = (
  data: GetUserRegistrations,
  softwareIds: (string | null)[]
) => {
  // the customer portal team are going to work on a filter for this endpoint
  // but it doesn't exist currently. We filter the six ids that identify
  // (in gatsby-config) software in this list of products

  return (
    data.registrations?.filter(
      (product: GetUserRegistrationsRegistrationsInner) =>
        !softwareIds.includes(product.productId!)
    ) || []
  );
};

export const fetchHardwareRegistrations = async (
  api: UserApi,
  softwareIds?: (string | null)[] | null
): Promise<GetUserRegistrationsRegistrationsInner[]> => {
  const response = await api.getUserRegistrations();
  return filterSoftware(response.data, softwareIds || []);
};

export const getHardwareCoupon = (
  userHardware: GetUserRegistrationsRegistrationsInner[],
  location: WindowLocation
): string | null => {
  if (userHardware.length < 1) {
    return null;
  }

  const params = new URLSearchParams(location.search);
  const currentPlugin = params.get('slug');
  if (!currentPlugin) {
    return null;
  }

  const coupon = couponsMap.get(currentPlugin);
  if (!coupon) {
    return null;
  }

  return isPerpetual(location) ? coupon.Perpetual : coupon.RTO;
};
