import {
  PurchaseRequestBody,
  CouponSubscriptionRequestBody,
  SubscriptionRequestBody,
} from '../components/PaymentFormRecurlyContainer/PaymentFormRecurlyContainer.utils';
import {PaymentsApi} from '@focusrite-novation/ampify-api';
import {AxiosError} from 'axios';

export class PaymentsError extends Error {
  name = 'PaymentsError';
  constructor(
    message: string,
    public errorCode: string,
    public threeDSecureActionTokenId: string
  ) {
    super(message);
  }
}

export const registerPurchase = async (
  requestBody: PurchaseRequestBody,
  paymentsApi: PaymentsApi
) => {
  const {couponCode} = requestBody;
  try {
    await paymentsApi.postUserPurchase(requestBody);
  } catch (error: any) {
    handlePaymentsApiError(error, couponCode);
  }
};

export const registerSubscription = async (
  requestBody: SubscriptionRequestBody | CouponSubscriptionRequestBody,
  paymentsApi: PaymentsApi
) => {
  const {couponCode} = requestBody;
  try {
    await paymentsApi.subscribe(requestBody);
  } catch (error: any) {
    handlePaymentsApiError(error, couponCode);
  }
};

const handlePaymentsApiError = (error: Error, couponCode?: string) => {
  const axiosError = error as AxiosError;
  const errorData: any = axiosError.response?.data;
  const displayMessage = errorData?.message;
  const errorCode = errorData?.errorCode;
  const threeDSecureActionTokenId = errorData?.threeDSecureActionTokenId;
  throw new PaymentsError(
    displayMessage
      ? displayMessage
      : couponCode
      ? 'Sorry, there was a problem with your coupon code.'
      : 'Sorry, there was a problem creating your subscription.',
    errorCode,
    threeDSecureActionTokenId
  );
};
