import React, {
  createRef,
  FunctionComponent,
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  CardElement,
  useCheckoutPricing,
  UseCheckoutPricingInput,
  useRecurly,
} from '@recurly/react-recurly';
import {
  Currency,
  getCurrencyFromCountryCode,
  PaymentFormContainer,
  PaymentFormFields,
} from '@focusrite-novation/ampify-web-ui';
import {
  CountryCode,
  User as PaymentUser,
} from '@focusrite-novation/ampify-web-ui/dist/types/components/PaymentForm/PaymentForm.models';
import {Coupon, User} from '@focusrite-novation/ampify-api';
import {
  createCardPurchaseRequestBody,
  createCardSubscriptionRequestBody,
  createCouponPurchaseRequestBody,
  createCouponSubscriptionRequestBody,
  getPrice,
  PurchaseRequestBody,
  SubscriptionRequestBody,
} from './PaymentFormRecurlyContainer.utils';
import styled, {css} from 'styled-components';
import {RecurlyError} from '@recurly/recurly-js';
import {addDiscountSuffix} from '../../utils/discount-suffix';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  hideCardInput?: boolean;
}
const Container = styled.div<ContainerProps>(
  ({hideCardInput}) => css`
    width: 100%;

    .recurly-element-card {
      ${hideCardInput &&
      css`
        pointer-events: none;
        background: #f2f2f2;
      `}
    }
  `
);

interface PaymentFormRecurlyContainerProps {
  /** User info is used to auto populate the form and the id is passed back when submitting */
  user: User;
  /** Countries that the user is allowed to purchase from */
  supportedCountries: string[];
  /** Set generic error message */
  error?: string;
  /** Form submission success message, displayed at the top of the form */
  successMessage?: string;
  /** Submit button text */
  submitButtonText: string;
  /** create subscription or purchase item, triggered on submit */
  handleSubmit: (
    requestBody: SubscriptionRequestBody | PurchaseRequestBody
  ) => Promise<any>;
  /** find a coupon when user applies one */
  fetchCoupon: (couponId: string) => Promise<Coupon>;
  /** Use to pre-populate the form with values */
  initialPaymentFormFieldValues?: Partial<PaymentFormFields>;
  /** Breakpoint at which the smaller collapsed version should be displayed */
  collapsedBreakpoint?: string;
  /** The id of the selected plan */
  planId: string;
  /** The itemCode of a selected plan (if perpetual) */
  itemId: string;
  /** Number of payments for a subscription */
  subscriptionTerm: number;
  /** Indicates whether the software is already accessible to the user */
  hasAccess: boolean;
}

export const PaymentFormRecurlyContainer: FunctionComponent<
  PaymentFormRecurlyContainerProps
> = ({handleSubmit, error, user, supportedCountries, hasAccess, ...props}) => {
  const recurly = useRecurly();
  const form = createRef<HTMLFormElement>();
  const [recurlyPricingError, setRecurlyPricingError] =
    useState<RecurlyError>();
  const [priceState, setPricing] = useCheckoutPricing(
    {
      currency: getCurrencyFromCountryCode(user.country as CountryCode),
    },
    setRecurlyPricingError
  );

  const isPerpetual = Boolean(props.itemId?.length > 0);

  const defaultPriceMessage = `You will be billed ${
    isPerpetual ? '' : `${props.subscriptionTerm} payments of `
  }`;
  const [priceMessage, setPriceMessage] = useState<string | ReactElement>(
    defaultPriceMessage
  );

  const [payingByCoupon, setPayingByCoupon] = useState<boolean>(
    priceState?.price?.next?.discount !== '0.00' &&
      priceState?.price?.next?.total === '0.00'
  );

  const [errorMessage, setErrorMessage] = useState<string>('');

  const requestRecurlyToken = (form: HTMLFormElement) =>
    new Promise<string>((resolve, reject) => {
      recurly.token(form, (error, token) =>
        error ? reject(error) : resolve(token.id)
      );
    });

  const handleOnSubmit = async (
    values: PaymentFormFields,
    user: User,
    currency: Currency
  ) => {
    let token: string;
    try {
      if (payingByCoupon) {
        if (!values.couponCode) {
          setErrorMessage('Please fill out the coupon code');
          return;
        }

        // force to UK so free item can be claimed from anywhere
        values.country = 'GB';

        const request = isPerpetual
          ? createCouponPurchaseRequestBody(values, props.itemId, user)
          : createCouponSubscriptionRequestBody(values, props.planId, user);

        return handleSubmit(request);
      } else {
        token = await requestRecurlyToken(form.current!);
        if (isPerpetual) {
          return handleSubmit(
            createCardPurchaseRequestBody(
              values,
              props.itemId,
              user,
              currency,
              token
            )
          );
        }
        return handleSubmit(
          createCardSubscriptionRequestBody(
            values,
            props.planId,
            user,
            currency,
            token
          )
        );
      }
    } catch (error: any) {
      const errorMessage =
        error.name === 'validation'
          ? 'Please check your card details and try again'
          : error.message
          ? error.message
          : 'Your card details appear to be incorrect. Please try again.';
      setErrorMessage(errorMessage);
    }
  };

  const createTemporalAmountText = (coupon: Coupon) => {
    return `${coupon.temporalAmount} ${coupon.temporalUnit} ${
      coupon.temporalAmount === 1 ? '' : 's'
    }`;
  };

  const createTrialDiscountMessage = (coupon: Coupon) => {
    return `You're saving ${
      coupon.discountPercentage
    }%! You will not be billed for ${createTemporalAmountText(
      coupon
    )}, then you will be billed ${
      isPerpetual ? '' : `${props.subscriptionTerm} payments of `
    }`;
  };

  const createImmediateDiscountMessage = (discountPercentage?: number) => {
    return `You're saving ${discountPercentage}%!
           ${
             discountPercentage === 100
               ? 'You will be not be billed. '
               : defaultPriceMessage
           }`;
  };

  const getPriceMessage = (coupon: Coupon) => {
    if (coupon.type === 'percent' && !coupon.discountPercentage) {
      return (
        <>
          <b>⭐ Discount applied!</b> {defaultPriceMessage}
        </>
      );
    }

    return coupon.type === 'trial'
      ? createTrialDiscountMessage(coupon)
      : createImmediateDiscountMessage(coupon.discountPercentage);
  };

  const isCouponRedeemable = (coupon: Coupon) =>
    coupon.state === 'redeemable' &&
    (coupon.plans!.includes(props.planId) ||
      coupon.items!.includes(props.itemId));

  const formatRecurlyPricingError = (error: RecurlyError | undefined) => {
    if (error?.message) {
      error.message =
        error.message.charAt(0).toUpperCase() + error.message.slice(1);
    }

    return error;
  };

  const handlePricingChanged = async (
    values: PaymentFormFields,
    currency: Currency
  ) => {
    const hasCouponCode = Boolean(values.couponCode!.length > 0);

    if (hasCouponCode) {
      values.couponCode = values.couponCode!.replace('\t', '').trim();
    }

    values.couponCode = addDiscountSuffix(values.couponCode, isPerpetual);

    const shippingAddress = {
      first_name: values.firstName,
      last_name: values.lastName,
      country: values.country,
    };

    let pricingOptions: UseCheckoutPricingInput = {
      shippingAddress,
      currency,
      coupon: hasCouponCode ? values.couponCode : '',
    };

    const setDefaultPriceMessage = () => {
      setPriceMessage(defaultPriceMessage);
      setPayingByCoupon(false);
    };

    const updateSavingsMessage = async () => {
      if (hasCouponCode) {
        try {
          const coupon = await props.fetchCoupon(values.couponCode!);
          if (isCouponRedeemable(coupon)) {
            const message = getPriceMessage(coupon);
            setPriceMessage(message);

            if (coupon.discountPercentage === 100) {
              setPayingByCoupon(true);
            }
          } else {
            setDefaultPriceMessage();
          }
        } catch (error) {
          console.error(error);
          setDefaultPriceMessage();
        }
      }
    };

    await updateSavingsMessage();

    if (isPerpetual) {
      pricingOptions.adjustments = [
        {
          itemCode: props.itemId,
          quantity: 1,
          currency,
        },
      ];
    } else {
      pricingOptions.subscriptions = [
        {
          plan: props.planId,
          quantity: 1,
        },
      ];
    }

    setPricing(pricingOptions);
  };

  useEffect(() => {
    handlePricingChanged(
      {
        email: user.email as string,
        country: user.country as string,
        firstName: user.firstName as string,
        lastName: user.lastName as string,
        postalCode: '',
        street: '',
        street2: '',
        region: '',
        city: '',
        couponCode: props.initialPaymentFormFieldValues?.couponCode || '',
      },
      getCurrencyFromCountryCode(user.country as CountryCode)
    );
  }, []);

  useEffect(() => {
    if (hasAccess) {
      setErrorMessage('You already have access to this plugin');
    }
  }, [hasAccess]);

  return (
    <Container hideCardInput={payingByCoupon} data-testid="payment-form">
      <PaymentFormContainer
        form={form}
        onSubmit={handleOnSubmit}
        renderCardInput={() => <CardElement />}
        supportedCountries={supportedCountries}
        onPricingChanged={handlePricingChanged}
        price={getPrice(priceState, {
          perpetual: isPerpetual,
        })}
        errorShouldReplaceLabel={true}
        priceMessage={payingByCoupon ? priceMessage : undefined}
        priceMessageForTotal={payingByCoupon ? undefined : priceMessage}
        user={user as PaymentUser}
        error={error ? error : errorMessage}
        couponError={formatRecurlyPricingError(recurlyPricingError)}
        collapsedBreakpoint="600px"
        hide={
          payingByCoupon
            ? {
                street: true,
                street2: true,
                city: true,
                region: true,
                country: true,
                postalCode: true,
                couponCode: false,
              }
            : {}
        }
        {...props}
      />
    </Container>
  );
};
